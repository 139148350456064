import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentMethodAddress } from '@kuna-pay/accept-payment/entities/payment-method';
import { PaymentMethodAddressAmount } from '@kuna-pay/accept-payment/entities/payment-method/ui/payment-method-address/payment-method-address-amount.component';
import { Timer } from '@kuna-pay/accept-payment/shared/ui/timer';

import { CheckoutPageModel } from '../../../page.model';
import styles from './invoice-address.module.scss';

type InvoiceAddressProps = {
  className?: string;
};

const InvoiceAddress = memo(({ className }: InvoiceAddressProps) => {
  const $$model = CheckoutPageModel.useModel();

  const invoice = useUnit($$model.$invoice)!;

  return (
    <PaymentMethodAddress
      amount={
        <PaymentMethodAddressAmount
          className={styles.invoiceAmount}
          paymentAmount={invoice.paymentAmount}
          equivalentAmount={invoice.invoiceAmount}
          PaymentAsset={invoice.PaymentAsset!}
          EquivalentAsset={invoice.InvoiceAsset}
        />
      }
      className={className}
      address={invoice.Address!.address}
      memo={invoice.Address!.memo}
      Product={invoice.Product!}
      PaymentAsset={invoice.PaymentAsset!}
      network={invoice.PaymentMethod!.network}
      companyAvatar={invoice.Company.avatar}
      showScanQRCta
    >
      <TimeRemaining />
    </PaymentMethodAddress>
  );
});

const TimeRemaining = memo(() => {
  const $$model = CheckoutPageModel.useModel();
  const { t } = useTranslation();
  const timeLeft = useUnit($$model.$$paymentAwaiting.$$timer.$timeLeft);

  const isNumber = typeof timeLeft === 'number';

  if (!isNumber) {
    return null;
  }

  return (
    <Timer
      className={styles.timer}
      title={t('pages.checkout.proceed-payment.time-remaining.title')}
      timeInSeconds={timeLeft}
    />
  );
});

export { InvoiceAddress };
