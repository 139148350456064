import { trackPageVisibility } from '@withease/web-api';
import type { Event } from 'effector';
import { createEvent, createStore, merge, sample } from 'effector';
import { interval } from 'patronum';

import { bridge } from '@kuna-pay/utils/misc';

/**
 * Start interval on external start
 * Stop interval on external stop
 *
 * Stop interval on page hidden
 * Start interval on page visible if interval is started before page hidden
 */

const createOffloadedInterval = (config: {
  start?: Event<void>;
  stop?: Event<void>;

  timeoutMS: number;
}) => {
  const start = config.start ?? createEvent();
  const stop = config.stop ?? createEvent();

  const startInterval = createEvent();
  const stopInterval = createEvent();

  const $pendingInterval = createStore(false);

  const { visible, hidden } = trackPageVisibility({
    setup: start,
    teardown: config.stop,
  });

  const $$interval = interval({
    start: startInterval,
    timeout: config.timeoutMS,
    stop: merge([stopInterval, stop]),
  });

  bridge(() => {
    sample({
      clock: start,
      target: startInterval,
    });
  });

  bridge(() => {
    $pendingInterval.on(start, () => true).on(stop, () => false);
  });

  bridge(() => {
    sample({
      clock: hidden,
      filter: $$interval.isRunning,
      target: stopInterval,
    });
  });

  bridge(() => {
    sample({
      clock: visible,
      filter: $pendingInterval,
      target: startInterval,
    });
  });

  return {
    start,
    stop,

    tick: sample({ clock: $$interval.tick, filter: $$interval.isRunning }),
  };
};

export { createOffloadedInterval };
