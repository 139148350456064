import { useMemo } from 'react';
import { memo } from 'react';

import { Link } from '@kuna-pay/ui/router';
import { BaseButton } from '@kuna-pay/ui/ui/button';

import { ReactComponent as TRUSTWalletLogoIcon } from './assets/trust-wallet-logo.svg';
import styles from './pay-with-buttons.module.scss';

type PayWithTrustWalletButtonProps = {
  amount: string;
  address: string;
  memo?: string | null;

  asset: string;
};

const PayWithTRUSTWalletButton = memo(
  ({ asset, amount, address, memo }: PayWithTrustWalletButtonProps) => {
    const payWithTRUSTWalletLink = useMemo(() => {
      const base = new URL('https://link.trustwallet.com/send');

      base.searchParams.append('asset', asset);
      base.searchParams.append('address', address);
      base.searchParams.append('amount', amount);

      if (memo) {
        base.searchParams.append('memo', memo);
      }

      return base.toString();
    }, [asset, amount, address]);

    if (!asset) {
      return null;
    }

    return (
      <BaseButton className={styles.payButton} fullWidth asChild>
        <Link to={payWithTRUSTWalletLink} target='_blank'>
          <TRUSTWalletLogoIcon />
        </Link>
      </BaseButton>
    );
  }
);

export type { PayWithTrustWalletButtonProps };
export { PayWithTRUSTWalletButton };
