import clsx from 'clsx';
import { useMemo } from 'react';
import { memo } from 'react';

import { Link } from '@kuna-pay/ui/router';
import { BaseButton } from '@kuna-pay/ui/ui/button';

import { ReactComponent as KunaLogoIcon } from './assets/kuna-logo.svg';
import styles from './pay-with-buttons.module.scss';

type PayWithKunaButtonProps = {
  code: string;
  method: string;
  amount: string;
  address: string;
  memo?: string | null;
  expiresAt?: string | null;
};

const PayWithKunaButton = memo(
  ({
    memo,
    code,
    method,
    expiresAt,
    amount,
    address,
  }: PayWithKunaButtonProps) => {
    const payWithKunaLink = useMemo(() => {
      const base = new URL('https://kuna.io/withdraw/prefill');

      base.searchParams.append('asset', code);
      base.searchParams.append('method', method);
      base.searchParams.append('amount', amount);
      base.searchParams.append('address', address);

      if (memo) {
        base.searchParams.append('memo', memo);
      }

      if (expiresAt) {
        base.searchParams.append('expiresAt', expiresAt);
      }

      return base.toString();
    }, [code, method, amount, address, memo, expiresAt]);

    return (
      <BaseButton
        className={clsx(styles.payButton, styles.payButtonPrimary)}
        fullWidth
        asChild
      >
        <Link to={payWithKunaLink} target='_blank'>
          <KunaLogoIcon />
        </Link>
      </BaseButton>
    );
  }
);

export type { PayWithKunaButtonProps };
export { PayWithKunaButton };
