import {
  FindDonationForNonAuthOutput,
  QueryFindDonationDetailsForNonAuthArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findDonationDetailsForNonAuthRequest = (
  select: Select<FindDonationForNonAuthOutput> | string
) => `query findDonationDetailsForNonAuth($companyId: String!, $addressId: String!) {
  data: findDonationDetailsForNonAuth(
    companyId: $companyId
    addressId: $addressId
  ) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'FindDonationForNonAuthOutput')}`;
export const findDonationDetailsForNonAuth =
  (select: Select<FindDonationForNonAuthOutput> | string) =>
  (args: QueryFindDonationDetailsForNonAuthArgs) =>
    query<FindDonationForNonAuthOutput>(
      findDonationDetailsForNonAuthRequest(select),
      args
    );
