import type { Effect } from 'effector';
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
} from 'effector';

import { getState, listen, setState } from '@kuna-pay/utils/effector';

function createSyncSearch<Option>(config: {
  getOptionsFx: Effect<void, Option[]>;

  match: (option: Option, query: string) => boolean;
}) {
  const getOptionsFx = attach({ effect: config.getOptionsFx });
  const loadOptionsFx = createEffect(async () => {
    const isAlreadyPending = await getState(getOptionsFx.pending);

    if (isAlreadyPending) {
      return;
    }

    const options = await getOptionsFx();

    setState($options, options);
  });

  //commands
  const reset = createEvent();
  const clear = createEvent();

  //events
  const changed = createEvent<string>();
  const cleared = createEvent();

  const $options = createStore<Option[]>([]);
  const $query = restore(changed, '');

  const $filteredOptions = combine($options, $query, (options, query) => {
    if (!query) return options;

    return options.filter((option) => config.match(option, query));
  });

  listen({
    clock: clear,
    handler: () => {
      cleared();
      $query.reinit!();
    },
  });

  listen({
    clock: reset,
    handler: () => {
      $query.reinit!();
      $options.reinit!();
    },
  });

  return {
    loadFx: loadOptionsFx,
    clear,
    reset,

    $query,

    $$ui: {
      changed,
      cleared,

      $query,
      $options: $filteredOptions,
      $allOptions: $options,

      $loading: getOptionsFx.pending,
    },
  };
}

export { createSyncSearch };
