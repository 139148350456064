import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { HorizontalStepper } from '@kuna-pay/accept-payment/shared/ui/horizontal-stepper';

import { DonationPageModel } from '../../page.model';
import styles from './donation-progress-tracker.module.scss';

const DonationProgressTracker = memo(
  ({ className }: { className?: string }) => {
    const { $$progress } = DonationPageModel.useModel();
    const { t } = useTranslation();
    const state = useUnit($$progress.$state);

    return (
      <HorizontalStepper.Root
        className={clsx(styles.root, styles.withoutDetails, className)}
      >
        <HorizontalStepper.Step.Root state={state.init} align='start'>
          <HorizontalStepper.Step.Connector nextStepAlign='center' />

          <HorizontalStepper.Step.Icon />

          <HorizontalStepper.Step.Label>
            {t('pages.checkout.shared.checkout-progress-tracker.init')}
          </HorizontalStepper.Step.Label>
        </HorizontalStepper.Step.Root>

        <HorizontalStepper.Step.Root state={state.payment} align='center'>
          <HorizontalStepper.Step.Connector
            state={state.payment === 'pending' ? 'active' : state.payment}
            nextStepAlign='end'
          />

          <HorizontalStepper.Step.Icon />

          <HorizontalStepper.Step.Label>
            {t('pages.checkout.shared.checkout-progress-tracker.payment')}
          </HorizontalStepper.Step.Label>
        </HorizontalStepper.Step.Root>

        <HorizontalStepper.Step.Root state={state.done} align='end'>
          <HorizontalStepper.Step.Icon />

          <HorizontalStepper.Step.Label>
            {t('pages.checkout.shared.checkout-progress-tracker.done')}
          </HorizontalStepper.Step.Label>
        </HorizontalStepper.Step.Root>
      </HorizontalStepper.Root>
    );
  }
);

export { DonationProgressTracker };
