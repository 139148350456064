import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { PropsWithChildren, ReactNode } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowLeftIcon } from '@kuna-pay/ui/icons';
import { IconButton } from '@kuna-pay/ui/ui/button';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { SelectPaymentMethodSearchInput } from './select-payment-search-input.component';
import { SelectPaymentMethodSearchResults } from './select-payment-search-results.component';
import type { PaymentMethodSyncSelectModel } from './sync-select.model';
import styles from './select-payment-method.module.scss';

type SelectPaymentMethodProps = PropsWithChildren & {
  className?: string;
  prepend?: ReactNode;

  $$model: PaymentMethodSyncSelectModel;
};

const SelectPaymentMethod = memo(
  ({ children, prepend, className, $$model }: SelectPaymentMethodProps) => {
    const { t } = useTranslation();

    const [isOpen, onGoBackClick] = useUnit([
      $$model.$isOpen,
      $$model.goBackClicked,
    ]);

    if (!isOpen) return <>{children}</>;

    return (
      <>
        {prepend}

        <Paper className={clsx(className, styles.paper)}>
          <div className={styles.root}>
            <div className={styles.header}>
              <IconButton onClick={onGoBackClick}>
                <ArrowLeftIcon />
              </IconButton>

              <Typography className={styles.headerText} variant='h6'>
                {t(
                  'pages.checkout.choose-payment-method.select-currency.header'
                )}
              </Typography>
            </div>

            <SelectPaymentMethodSearchInput $$model={$$model} />

            <SelectPaymentMethodSearchResults $$model={$$model} />
          </div>
        </Paper>
      </>
    );
  }
);

export type { SelectPaymentMethodProps };
export { SelectPaymentMethod };
