import { createEffect, createEvent, createStore, sample } from 'effector';

import { createSyncSearch } from '@kuna-pay/utils/effector/search';
import { bridge } from '@kuna-pay/utils/misc';
import { objectEntries } from '@kuna-pay/utils/typescript';

import type { PaymentMethod, PaymentMethodOption } from '../../types';

type CreateSyncSelectModelConfig<Option, Value> = {
  getOptionsFx: () => Promise<Option[]>;

  valueAdapter: (prevValue: Value | null, option: Option) => Value | null;
};

type PaymentMethodSyncSelectModel = ReturnType<
  typeof createPaymentMethodSyncSelectModel
>['$$ui'];

function createPaymentMethodSyncSelectModel(
  config: CreateSyncSelectModelConfig<PaymentMethodOption, PaymentMethod>
) {
  const getPaymentMethodsFx = createEffect(async () => config.getOptionsFx());

  //commands
  const init = createEvent<PaymentMethod | null>();

  //events
  const buttonClicked = createEvent();
  const searchItemClicked = createEvent<PaymentMethodOption>();
  const goBackClicked = createEvent();

  //stores
  const $isOpen = createStore(false);
  const $value = createStore<PaymentMethod | null>(null, {
    updateFilter: (prevValue, nextValue) => {
      if (prevValue === null || nextValue === null) return true;

      //shallowEqual
      return objectEntries(prevValue).some(
        ([key, value]) => nextValue[key] !== value
      );
    },
  });

  //models
  const $$search = createSyncSearch({
    getOptionsFx: getPaymentMethodsFx,

    match: (option, query) =>
      [option.asset, option.network]
        .filter(Boolean)
        .some((str) => str!.toLowerCase().includes(query.toLowerCase())),
  });

  bridge(() => {
    $value.on(init, (_, value) => value);
  });

  bridge(() => {
    $isOpen.on(buttonClicked, () => true);
  });

  bridge(() => {
    $isOpen.on(searchItemClicked, () => false);

    $value.on(searchItemClicked, config.valueAdapter);
  });

  bridge(() => {
    sample({
      clock: goBackClicked,
      target: $$search.clear,
    });

    $isOpen.on(goBackClicked, () => false);
  });

  return {
    loadFx: $$search.loadFx,
    init,

    $value,

    changed: searchItemClicked,

    $$ui: {
      $$search: $$search.$$ui,

      $isOpen,

      $value,

      buttonClicked,
      searchItemClicked,
      goBackClicked,
    },
  };
}

export { createPaymentMethodSyncSelectModel };
export type { CreateSyncSelectModelConfig, PaymentMethodSyncSelectModel };
