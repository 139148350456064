import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { Paper } from '@kuna-pay/ui/ui/paper';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import { PaymentMethodAddress } from '@kuna-pay/accept-payment/entities/payment-method';

import { DonationPageModel } from '../../page.model';
import { DonationProgressTracker } from '../donation-progress-tracker';
import styles from './procced-payment.module.scss';

type ProceedPaymentViewProps = PropsWithChildren & {
  className?: string;
};

const ProceedPaymentView = memo(
  ({ className, children }: ProceedPaymentViewProps) => {
    const $$model = DonationPageModel.useModel();

    const donation = useUnit($$model.$$donationQuery.$data)!;

    return (
      <>
        <DonationProgressTracker />

        <Paper className={clsx(styles.root, className)}>
          <div className={styles.container}>
            <PaymentMethodAddress
              amount={<ExchangeRate />}
              address={donation.address!}
              memo={donation.memo}
              PaymentAsset={donation.PaymentMethod!.Asset!}
              Product={donation.PaymentMethod!.Product}
              network={donation.PaymentMethod!.network}
              companyAvatar={donation.Company.avatar}
              showScanQRCta
              scanQrCtaText='entities.payment-method.address.cta-1'
            />

            {children}
          </div>
        </Paper>
      </>
    );
  }
);

const ExchangeRate = memo(() => {
  const $$model = DonationPageModel.useModel();

  const AssetFormat = useAssetFormat();
  const donation = useUnit($$model.$$donationQuery.$data)!;
  const [isLoading, isFetching, isError, rate] = useUnit([
    $$model.$$rates.$isLoading,
    $$model.$$rates.$isFetching,
    $$model.$$rates.$isError,
    $$model.$rate,
  ])!;

  if (isLoading || isError || !rate) {
    return (
      <div className={styles.exchangeRate}>
        <Typography className='text-black600' variant='numbers1'>
          Exchange rate
        </Typography>

        <Skeleton width={100} height={20} borderRadius={4} />
      </div>
    );
  }

  const formattedOneEquivalentAssetAmount = AssetFormat.formatAmount(
    1,
    donation.EquivalentAsset
  );
  const formattedPaymentMethodAssetExchangeRateAmount =
    AssetFormat.formatAmount(rate.toCurrency, donation.PaymentMethod!.Asset);

  const exchangeRateStr = `${formattedOneEquivalentAssetAmount} ≈ ${formattedPaymentMethodAssetExchangeRateAmount}`;

  return (
    <div className={styles.exchangeRate}>
      <Typography className='text-black600' variant='numbers1'>
        Exchange rate
      </Typography>

      <Typography
        className={clsx({ 'text-black600': isFetching })}
        variant='subtitle4'
        title={exchangeRateStr}
      >
        {exchangeRateStr}
      </Typography>
    </div>
  );
});

export { ProceedPaymentView };
