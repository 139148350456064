import { Rate } from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findManyRateForNonAuthRequest = (
  select: Select<Rate> | string
) => `query findManyRateForNonAuth {
  data: findManyRateForNonAuth {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'Rate')}`;
export const findManyRateForNonAuth = (select: Select<Rate> | string) => () =>
  query<Rate[]>(findManyRateForNonAuthRequest(select));
