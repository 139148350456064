import type { NonAuthInvoiceDetailsOutput } from '@kuna-pay/accept-payment/generated/graphql';

import type { PaymentMethod, PaymentMethodOption } from '../types';

class PaymentMethodAdapter {
  public static fromPaymentMethodOption({
    code,
    asset,
    Asset,
    network,
    id,
  }: PaymentMethodOption) {
    return {
      id,
      code,
      name: Asset!.name,
      icon: (Asset!.payload?.icons?.svg ?? '') as string,
      network,
      asset,
      Asset,
    };
  }

  public static fromPublicInvoiceOutput(
    dto: NonAuthInvoiceDetailsOutput
  ): PaymentMethod | null {
    if (!dto?.PaymentMethod || !dto.PaymentAsset) return null;

    const {
      PaymentAsset,
      PaymentMethod: { id, code, asset, network },
    } = dto; // PublicInvoiceOutput;

    return {
      id,
      code,
      name: PaymentAsset?.name,
      icon: (PaymentAsset?.payload?.icons?.svg ?? '') as string,
      network,
      asset,
      Asset: PaymentAsset,
    };
  }
}

export { PaymentMethodAdapter };
