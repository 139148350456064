/**
 * This class is responsible for persisting the donation ID in the session storage.
 * This is useful for the donation page to remember the donation ID when the user reloads page,
 * so user can continue the donation process instead of starting over.
 */
class DonationPersistence {
  public static SESSION_STORAGE_KEY = 'current-donation-id';

  public static getAddressId(): string | null {
    try {
      const maybeJsonAddressId = sessionStorage.getItem(
        this.SESSION_STORAGE_KEY
      );

      if (!maybeJsonAddressId) {
        return null;
      }

      const maybeAddressId = JSON.parse(maybeJsonAddressId);

      if (typeof maybeAddressId !== 'string') {
        return null;
      }

      const addressId = maybeAddressId;

      return addressId;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public static setAddressId(id: string) {
    try {
      const jsonAddressId = JSON.stringify(id);

      sessionStorage.setItem(this.SESSION_STORAGE_KEY, jsonAddressId);
    } catch (error) {
      console.error(error);
    }
  }

  public static clearAddressId() {
    try {
      sessionStorage.removeItem(this.SESSION_STORAGE_KEY);
    } catch (error) {
      console.error(error);
    }
  }
}

export { DonationPersistence };
