import { useUnit } from 'effector-react';
import { memo } from 'react';

import { AssetIcon } from '@kuna-pay/ui/icons';
import { BaseButton } from '@kuna-pay/ui/ui/button';
import { Tag } from '@kuna-pay/ui/ui/tag';
import { Typography } from '@kuna-pay/ui/ui/typography';

import type { PaymentMethodSyncSelectModel } from './sync-select.model';
import styles from './select-payment-method.module.scss';

type SelectPaymentMethodSearchResultsProps = {
  $$model: PaymentMethodSyncSelectModel;
};

const SelectPaymentMethodSearchResults = memo(
  ({ $$model }: SelectPaymentMethodSearchResultsProps) => {
    const [options, onOptionClick] = useUnit([
      $$model.$$search.$options,
      $$model.searchItemClicked,
    ]);

    return (
      <div className={styles.searchResults} role='list'>
        {options.map((option) => {
          const { network, code, asset, Asset } = option;

          return (
            <BaseButton
              key={`${code}${network}${Asset!.name}${asset}`}
              className={styles.button}
              onClick={() => onOptionClick(option)}
            >
              {!!Asset?.payload?.icons?.svg ? (
                <img
                  className={styles.buttonAssetIcon}
                  src={Asset.payload.icons.svg}
                  alt=''
                />
              ) : (
                <AssetIcon className={styles.buttonAssetIcon} />
              )}

              <span className={styles.buttonContent}>
                <Typography className={styles.buttonText} variant='subtitle5'>
                  {asset}
                </Typography>

                {!!network && (
                  <Tag className={styles.buttonContentTag} variant='common'>
                    {network}
                  </Tag>
                )}
              </span>
            </BaseButton>
          );
        })}
      </div>
    );
  }
);

export type { SelectPaymentMethodSearchResultsProps };
export { SelectPaymentMethodSearchResults };
