import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchField } from '@kuna-pay/ui/ui/text-field';

import type { PaymentMethodSyncSelectModel } from './sync-select.model';
import styles from './select-payment-method.module.scss';

type SelectPaymentSearchInputProps = {
  $$model: PaymentMethodSyncSelectModel;
};

const SelectPaymentMethodSearchInput = memo(
  ({ $$model }: SelectPaymentSearchInputProps) => {
    const { t } = useTranslation();

    const [query, onQueryChange, onQueryClear] = useUnit([
      $$model.$$search.$query,
      $$model.$$search.changed,
      $$model.$$search.cleared,
    ]);

    return (
      <SearchField
        className={styles.searchInput}
        classes={{ input: styles.searchInputInput }}
        type='search'
        placeholder={
          t(
            'pages.checkout.choose-payment-method.select-currency.search.placeholder'
          )!
        }
        value={query}
        onChange={(event) => onQueryChange(event.target.value)}
        onClear={onQueryClear}
        autoComplete='off'
      />
    );
  }
);

export type { SelectPaymentSearchInputProps };
export { SelectPaymentMethodSearchInput };
