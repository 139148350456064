import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { memo, useEffect, useRef, useState } from 'react';

import { DropdownIcon } from '@kuna-pay/ui/icons';
import { BaseButton } from '@kuna-pay/ui/ui/button';
import { Divider } from '@kuna-pay/ui/ui/divider';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './accordion.module.scss';

type AccordionProps = PropsWithChildren & {
  title: string;

  defaultOpen?: boolean;

  classes?: {
    root?: string;
    button?: string;
    buttonText?: string;
    buttonIcon?: string;
    content?: string;
  };
};

const Accordion = memo(
  ({ title, children, classes, defaultOpen = false }: AccordionProps) => {
    const [isOpen, setOpen] = useState(defaultOpen);

    const ref = useRef<HTMLDivElement>(null);

    const onButtonClick = () => {
      setOpen((open) => !open);
    };

    useEffect(() => {
      if (!ref.current) return;

      if (!isOpen) return;

      ref.current.scrollIntoView();
    }, [isOpen]);

    return (
      <div className={clsx(styles.root, classes?.root)}>
        <BaseButton
          className={clsx(styles.button, classes?.button, {
            [styles.open]: isOpen,
          })}
          onClick={onButtonClick}
        >
          <Typography className={classes?.buttonText} variant='subtitle5'>
            {title}
          </Typography>

          <DropdownIcon
            className={clsx(styles.dropdownIcon, classes?.buttonIcon, {
              [styles.open]: isOpen,
            })}
          />
        </BaseButton>

        {isOpen && (
          <div ref={ref} className={clsx(styles.content, classes?.content)}>
            <Divider />

            {children}
          </div>
        )}
      </div>
    );
  }
);

export { Accordion };
