import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import type { ToastContainerProps } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

type NotificationProviderProps = PropsWithChildren & ToastContainerProps;

const NotificationProvider = memo(
  ({ children, ...props }: NotificationProviderProps) => (
    <>
      {children}

      <ToastContainer closeButton={false} hideProgressBar {...props} />
    </>
  )
);

export type { NotificationProviderProps };
export { NotificationProvider };
