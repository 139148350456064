import type { CoreAsset } from './types';

const MAX_FREEZE_AMOUNT = 10000;

const ASSET_SELECT: Partial<Record<keyof CoreAsset, boolean>> = {
  type: true,
  code: true,
  name: true,
  payload: true,
  precision: true,
  position: true,
};

export { ASSET_SELECT, MAX_FREEZE_AMOUNT };
