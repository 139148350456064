import type { Select } from '@kuna/graphql-util/dist/select';

import { ASSET_SELECT } from '@kuna-pay/core/entities/asset';

import { PaymentMethodComparator } from '@kuna-pay/accept-payment/entities/payment-method';
import type { AssetPaymentMethod } from '@kuna-pay/accept-payment/generated/graphql';
import { findDetailsForDonationPageNonAuth } from '@kuna-pay/accept-payment/shared/api/generated/Deposit/request/findDetailsForDonationPageNonAuth';
import { findDonationDetailsForNonAuth } from '@kuna-pay/accept-payment/shared/api/generated/Deposit/request/findDonationDetailsForNonAuth';

import type {
  FindDetailsForDonationArgs,
  FindDetailsForDonationOutput,
} from './find-details-for-donation.args';

class FindDetailsForDonationQuery {
  public async query(
    args: FindDetailsForDonationArgs
  ): Promise<FindDetailsForDonationOutput> {
    if (args.addressId) {
      const [
        { Company, EquivalentAsset },
        { id, PaymentDetails, PaymentMethod, memo, address },
      ] = await Promise.all([
        findDetailsForDonationPageNonAuth({
          EquivalentAsset: ASSET_SELECT,
          Company: {
            id: true,
            title: true,
            avatar: true,
            websiteUrl: true,
            supportInfo: true,
          },
        })({ companyId: args.companyId }),

        findDonationDetailsForNonAuth({
          id: true,
          memo: true,
          address: true,
          PaymentDetails: { fee: true, amount: true, processedAmount: true },
          PaymentMethod: this.PAYMENT_METHOD_SELECT,
        })({ companyId: args.companyId, addressId: args.addressId }),
      ]);

      return {
        id,
        memo,
        address,
        Company,
        PaymentMethods: [],
        PaymentMethod,
        PaymentDetails,
        EquivalentAsset,
      };
    }

    const { Company, PaymentMethods, EquivalentAsset } =
      await findDetailsForDonationPageNonAuth({
        EquivalentAsset: ASSET_SELECT,
        Company: {
          id: true,
          title: true,
          avatar: true,
          websiteUrl: true,
          supportInfo: true,
        },
        PaymentMethods: this.PAYMENT_METHOD_SELECT,
      })({ companyId: args.companyId });

    PaymentMethods.sort(PaymentMethodComparator.ASC);

    return {
      id: null,
      memo: null,
      address: null,
      PaymentDetails: null,
      PaymentMethod: null,
      Company,
      PaymentMethods,
      EquivalentAsset,
    };
  }

  public readonly PAYMENT_METHOD_SELECT: Select<AssetPaymentMethod> = {
    id: true,
    code: true,
    network: true,
    position: true,
    asset: true,
    Product: {
      maxAmount: true,
      minAmount: true,
    },
    Asset: ASSET_SELECT,
  };
}

export { FindDetailsForDonationQuery };
