import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import {
  $$rateYourExperienceFeedback,
  RateYourExperienceFeedbackKey,
} from '@kuna-pay/accept-payment/shared/feedback';

import successImg from '../../../assets/success.png';
import { DonationPageModel } from '../../../page.model';
import styles from './paid.module.scss';

type DonationDetailsPaidProps = PropsWithChildren & {
  className?: string;
};

const DonationDetailsPaid = memo(
  ({ className, children }: DonationDetailsPaidProps) => {
    const $$model = DonationPageModel.useModel();
    const { t } = useTranslation();
    const AssetFormat = useAssetFormat();

    const donation = useUnit($$model.$$donationQuery.$data)!;

    useEffect(() => {
      $$rateYourExperienceFeedback.rate(
        RateYourExperienceFeedbackKey.DonationPayment
      );
    }, []);

    return (
      <Paper className={clsx(className, styles.root, styles.grow)}>
        <div className={styles.container}>
          <div className={styles.imgContainer}>
            <img src={successImg} width={112} height={112} alt='' />
          </div>

          <Typography className={styles.title} variant='h7'>
            {t('pages.donation.paid.title')}
          </Typography>

          <div className={styles.amountContainter}>
            <Typography className='text-black500' variant='body3'>
              {t('pages.donation.paid.amount')}
            </Typography>

            <Typography variant='h6'>
              {AssetFormat.formatAmount(
                donation.PaymentDetails!.amount,
                donation.PaymentMethod!.Asset
              )}
            </Typography>
          </div>

          <Button.Root
            className={styles.redirectButton}
            variant='contained'
            color='primary'
            size='xl'
            fullWidth
            asChild
          >
            <ExternalLink href='https://bihus.info/donate/' target='_self'>
              <Button.Text variant='subtitle3' size='lg'>
                {t('pages.donation.paid.redirect', {
                  replace: { to: donation.Company.title },
                })}
              </Button.Text>
            </ExternalLink>
          </Button.Root>

          {children}
        </div>
      </Paper>
    );
  }
);

export { DonationDetailsPaid };
