import { useMemo } from 'react';
import { memo } from 'react';

import { Link } from '@kuna-pay/ui/router';
import { BaseButton } from '@kuna-pay/ui/ui/button';

import { ReactComponent as KrakenLogoIcon } from './assets/kraken-logo.svg';
import styles from './pay-with-buttons.module.scss';

type PayWithKrakenButtonProps = {
  code: string;
};

const PayWithKrakenButton = memo(({ code }: PayWithKrakenButtonProps) => {
  const payWithKrakenLink = useMemo(() => {
    const base = new URL('https://www.kraken.com/c/funding/withdraw');

    base.searchParams.append('asset', code);

    return base.toString();
  }, [code]);

  return (
    <BaseButton className={styles.payButton} fullWidth asChild>
      <Link to={payWithKrakenLink} target='_blank'>
        <KrakenLogoIcon />
      </Link>
    </BaseButton>
  );
});

export type { PayWithKrakenButtonProps };
export { PayWithKrakenButton };
