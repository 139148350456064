import { memo } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useParams,
} from 'react-router-dom';

import { RouterErrorBoundary } from '@kuna-pay/ui/error-boundary';

import { DonationPage } from '@kuna-pay/accept-payment/pages/donation';

import { CheckoutPage } from './checkout';
import { CocktailPromoRoute } from './cocktail-promo';
import { NotFoundPage } from './not-found';

const Pages = memo(() => <RouterProvider router={router} />);

const router = createBrowserRouter([
  {
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: '/invoice/:id',
        Component: CheckoutPage,
      },
      {
        path: '/bihusinfo',
        Component: () => <DonationPage id='bihusinfo' />,
      },
      {
        path: '/cocktail-promo/:id',
        Component: () => <CocktailPromoRoute.Component />,
      },
      {
        path: '/cocktail-promo/:id/fireball',
        Component: () => <CocktailPromoRoute.Component price='14.00' />,
      },
      {
        path: '/cocktail-promo/:id/bluethereum',
        Component: () => <CocktailPromoRoute.Component price='13.00' />,
      },
      {
        path: '/cocktail-promo/:id/shotgpt',
        Component: () => <CocktailPromoRoute.Component price='7.00' />,
      },
      {
        path: '/cocktail-promo/:id/mocktail',
        Component: () => <CocktailPromoRoute.Component price='10.00' />,
      },
      {
        // Legacy mapping
        path: '/:id',
        Component: () => {
          const params = useParams<{ id: string }>();

          return <Navigate to={`/invoice/${params.id}`} />;
        },
      },
      {
        path: '*',
        Component: NotFoundPage,
      },
    ],
  },
]);

export { Pages };
