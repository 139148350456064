import { memo } from 'react';

import { useLocalModel } from '@kuna-pay/utils/react/use-local-model';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { State } from '@kuna-pay/core/shared/lib/state';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { ChangeLanguage } from '@kuna-pay/accept-payment/features/change-language';
import { Footer } from '@kuna-pay/accept-payment/shared/ui/footer';
import { NotFoundTemplate } from '@kuna-pay/accept-payment/shared/ui/not-found-template';
import { MainLayout } from '@kuna-pay/accept-payment/widgets/layouts/main';

import { DonationPageStep } from './model';
import { DonationPageModel } from './page.model';
import {
  ChoosePaymentMethod,
  DonationDetailsPaid,
  ProceedPaymentView,
} from './ui';
import styles from './page.module.scss';

type DonationPageProps = {
  id: string;
};

const DonationPage = memo(({ id }: DonationPageProps) => {
  const $$model = useLocalModel(() => DonationPageModel.createModel());

  useTypedGate({ Gate: $$model.PageGate, props: { id } });

  return (
    <DonationPageModel.Provider value={$$model.$$ui}>
      <State.Switch $$model={$$model.$$ui.$$state}>
        <State.Case
          value={DonationPageStep.Loading}
          view={
            <MainLayout
              classes={{
                root: styles.layout,
                content: styles.layoutContent,
                footer: styles.layoutFooter,
              }}
            >
              <Skeleton containerClassName={styles.loading} flex fullWidth />
            </MainLayout>
          }
        />

        <State.Case
          value={DonationPageStep.Failed}
          view={
            <MainLayout
              classes={{
                root: styles.layout,
                footer: styles.layoutFooter,
              }}
            >
              <NotFoundTemplate />
            </MainLayout>
          }
        />

        <State.Case
          value={DonationPageStep.Initialization}
          view={
            <MainLayout
              classes={{
                root: styles.layout,
                content: styles.layoutContent,
                footer: styles.layoutFooter,
              }}
            >
              <ChoosePaymentMethod className={styles.page}>
                <Footer className={styles.footer}>
                  <ChangeLanguage />
                </Footer>
              </ChoosePaymentMethod>
            </MainLayout>
          }
        />

        <State.Case
          value={DonationPageStep.Payment}
          view={
            <MainLayout
              classes={{
                root: styles.layout,
                content: styles.layoutContent,
                footer: styles.layoutFooter,
              }}
            >
              <ProceedPaymentView className={styles.page}>
                <Footer className={styles.footer}>
                  <ChangeLanguage />
                </Footer>
              </ProceedPaymentView>
            </MainLayout>
          }
        />

        <State.Case
          value={DonationPageStep.Done}
          view={
            <MainLayout
              classes={{
                root: styles.layout,
                content: styles.layoutContent,
                footer: styles.layoutFooter,
              }}
            >
              <DonationDetailsPaid className={styles.page}>
                <Footer className={styles.footer}>
                  <ChangeLanguage />
                </Footer>
              </DonationDetailsPaid>
            </MainLayout>
          }
        />
      </State.Switch>
    </DonationPageModel.Provider>
  );
});

DonationPage.displayName = 'CheckoutPage';

export default DonationPage;
