import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import type { NotificationProviderProps } from './notification';
import { NotificationProvider } from './notification';
import { TooltipProvider } from './ui/tooltip';

const UIProvider = memo(
  ({ children, ...props }: PropsWithChildren & NotificationProviderProps) => (
    <TooltipProvider delayDuration={250}>
      <NotificationProvider {...props}>{children}</NotificationProvider>
    </TooltipProvider>
  )
);

export { UIProvider };
