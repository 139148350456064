import type { StepState } from '@kuna-pay/ui/components/horizontal-stepper';

import type { PaymentMethod } from '@kuna-pay/accept-payment/entities/payment-method';

import { DonationPageStep } from './state.model';

type IProgressState = {
  init: StepState;
  payment: StepState;
  done: StepState;
};

class DonationProgress {
  public static progress(
    state: DonationPageStep,
    isSubmitting: boolean,
    selectedPaymentMethod: PaymentMethod | null
  ): IProgressState {
    switch (state) {
      case DonationPageStep.Loading: {
        return {
          init: 'inactive',
          payment: 'inactive',
          done: 'inactive',
        };
      }

      case DonationPageStep.Initialization: {
        return {
          init: isSubmitting || !!selectedPaymentMethod ? 'pending' : 'active',
          payment: 'inactive',
          done: 'inactive',
        };
      }

      case DonationPageStep.Payment: {
        return {
          init: 'completed',
          payment: 'active',
          done: 'inactive',
        };
      }

      case DonationPageStep.Done: {
        return {
          init: 'completed',
          payment: 'completed',
          done: 'completed',
        };
      }

      case DonationPageStep.Failed: {
        return {
          init: 'inactive',
          payment: 'inactive',
          done: 'inactive',
        };
      }

      default: {
        const _: never = state;

        return {
          init: 'inactive',
          payment: 'inactive',
          done: 'inactive',
        };
      }
    }
  }
}

export type { IProgressState };
export { DonationProgress };
