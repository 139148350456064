import { createEffect } from 'effector';

import { ASSET_SELECT } from '@kuna-pay/core/entities/asset';

import type { PaymentMethodOption } from '@kuna-pay/accept-payment/entities/payment-method';
import { PaymentMethodComparator } from '@kuna-pay/accept-payment/entities/payment-method';
import { findPublicPaymentMethodsToPayInvoice } from '@kuna-pay/accept-payment/shared/api/generated/Invoice/request/findPublicPaymentMethodsToPayInvoice';

const findPaymentMethodsFx = createEffect({
  handler: async (params: { id: string }): Promise<PaymentMethodOption[]> => {
    const paymentMethods = await findPublicPaymentMethodsToPayInvoice({
      id: true,
      code: true,
      network: true,
      position: true,
      asset: true,
      Product: {
        maxAmount: true,
        minAmount: true,
      },
      Asset: ASSET_SELECT,
    })(params);

    paymentMethods.sort(PaymentMethodComparator.ASC);

    return paymentMethods as PaymentMethodOption[];
  },
});

export { findPaymentMethodsFx };
